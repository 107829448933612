import type { Event, EventHint } from '@sentry/nextjs';

const errorIgnoreConditions = [
  'ContextualSupportContent.labels',
  'ContextualSupportContent.errorMessage',
];

function shouldIgnoreError(error: any, conditions: string[]) {
  const errorString = String(error);
  return conditions.some((condition) => errorString.includes(condition));
}

function beforeSend(event: Event, hint: EventHint) {
  const error = hint.originalException || hint.syntheticException;

  const errorShouldBeIgnored = shouldIgnoreError(error, errorIgnoreConditions);

  if (errorShouldBeIgnored) {
    return null;
  }

  // Check if the User-Agent is Synthetic-Monitoring-BrowserStack
  const userAgent = event.request?.headers?.['User-Agent'];

  if (userAgent === 'Synthetic-Monitoring-BrowserStack') {
    return null;
  }

  return event;
}

export default beforeSend;
