type Deeplinks = Links & {
  ex30: Links;
};

type Links = {
  IOS: Record<string, string>;
  Android: Record<string, string>;
  DEFAULT: string;
};

const APP_DEEPLINKS: Deeplinks = {
  ex30: {
    IOS: {
      'pilot-assist': '',
      'performance-mode': '',
    },
    Android: {
      'pilot-assist': '',
      'performance-mode': '',
    },
    DEFAULT: '',
  },
  IOS: {
    'pilot-assist': '',
    'performance-mode': '',
  },
  Android: {
    'pilot-assist': '',
    'performance-mode': '',
  },
  DEFAULT: 'volvooncall://learn-and-shop/shop',
};

export const VOLVO_APP_CONFIG = {
  IOS: 'https://apps.apple.com/us/app/volvo-cars/id439635293',
  ANDROID: 'https://play.google.com/store/apps/details?id=se.volvo.vcc',
  ANDROID_CHINA: 'https://voc.volvo.care/volvo-on-call-china.apk',
  IOS_EX30: 'https://apps.apple.com/app/volvo-ex30/id6446026534',
  ANDROID_EX30:
    'https://play.google.com/store/apps/details?id=com.volvocars.ex30',
  DEEPLINKS: APP_DEEPLINKS,
};
